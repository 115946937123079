import { useQuery, UseQueryResult, QueryKey } from '@tanstack/react-query';

export function useFetchQuery<T>(
  queryKey: QueryKey,
  fetchFunction: () => Promise<T>
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey,
    queryFn: fetchFunction,
  });
}

