import React, { useState, useEffect } from 'react';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { InsuranceDataType, VehicleBrandDataType, VehicleDataType } from '../interface/insuranceData';

import { useFetchQuery } from '../utils/useFetchQuery';
import { fetchInsuranceTypeData, fetchVehicleBrandData, fetchVehicleTypeData } from '../services/googleService';
interface InsuranceData {
  company: string;
  vehicleType: string;
  brand: string;
  model: string;
  sumInsured: number;
  premium6To10Years: number;
  premium11To20Years: number;
}

const InsuranceCalculator: React.FC = () => {
  const [companies, setCompanies] = useState<string[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);
  const [vehicleBrands, setVehicleBrands] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [sumInsured, setSumInsured] = useState<number>(0);
  const [premium, setPremium] = useState<number | null>(null);

  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [selectedVehicleType, setSelectedVehicleType] = useState<string>('');
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [selectedYearRange, setSelectedYearRange] = useState<'6-10' | '11-20'>('6-10');

  useEffect(() => {
    // โหลดข้อมูลจาก Google Sheets
    loadDataFromGoogleSheets();
  }, []);

  const { data: insuranceTypeData, isLoading: insuranceTypeLoading, error: insuranceTypeError } = useFetchQuery(
    ["insuranceData"],
    fetchInsuranceTypeData
  );

  const { data: vehicleTypeData, isLoading: vehicleTypeLoading, error: vehicleTypeError } = useFetchQuery(
    ["vehicleData"],
    fetchVehicleTypeData
  );

  const { data: vehicleBrandData, isLoading: vehicleBrandLoading, error: vehicleBrandError } = useFetchQuery(
    ["vehicleBrandData"],
    fetchVehicleBrandData
  );

  useEffect(() => {
    if (insuranceTypeData) {
      const companyNames = insuranceTypeData
        .filter((item: InsuranceDataType) => item.id !== 1) 
        .map((item: InsuranceDataType) => item.company_name);
      setCompanies(companyNames);
    }
  }, [insuranceTypeData]);

  useEffect(() => {
    if (vehicleTypeData) {
      const vehicleTypeNames = vehicleTypeData
        .filter((item: VehicleDataType) => item.id !== 1) 
        .map((item: VehicleDataType) => item.vehicle_type);
      setVehicleTypes(vehicleTypeNames);
    }
  }, [vehicleTypeData]);


  useEffect(() => {
    if (vehicleBrandData) {
      const vehicleBrandNames = vehicleBrandData
        .filter((item: VehicleBrandDataType) => item.id !== 1) 
        .map((item: VehicleBrandDataType) => item.vehicle_brand);
      setVehicleBrands(vehicleBrandNames);
    }
  }, [vehicleBrandData]);

  const loadDataFromGoogleSheets = async () => {
    // ใส่โค้ดสำหรับโหลดข้อมูลจาก Google Sheets ที่นี่
    // ตัวอย่าง:
    // const doc = new GoogleSpreadsheet('YOUR_SPREADSHEET_ID');
    // await doc.useServiceAccountAuth({...});
    // await doc.loadInfo();
    // const sheet = doc.sheetsByIndex[0];
    // const rows = await sheet.getRows();
    // ประมวลผลข้อมูลและอัปเดต state
  };

  const calculatePremium = () => {
    // คำนวณเบี้ยประกันภัยตามเงื่อนไขที่เลือก
    // อัปเดต state premium
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">คำนวณเบี้ยประกันภัยรถยนต์</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <select 
          className="select select-bordered w-full" 
          onChange={(e) => setSelectedCompany(e.target.value)}
          value={selectedCompany}
        >
          <option value="" disabled>เลือกบริษัทประกันภัย</option>
          {companies.map((company) => (
            <option key={company} value={company}>{company}</option>
          ))}
        </select>
        <select 
          className="select select-bordered w-full" 
          onChange={(e) => setSelectedVehicleType(e.target.value)}
          value={selectedVehicleType}
        >
          <option value="" disabled>เลือกประเภทรถยนต์</option>
          {vehicleTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
        <select 
          className="select select-bordered w-full" 
          onChange={(e) => setSelectedBrand(e.target.value)}
          value={selectedBrand}
        >
          <option value="" disabled>เลือกยี่ห้อรถยนต์</option>
          {vehicleBrands.map((brand) => (
            <option key={brand} value={brand}>{brand}</option>
          ))}
        </select>
        <select className="select select-bordered w-full" onChange={(e) => setSelectedModel(e.target.value)}>
          <option disabled selected>เลือกรุ่นรถยนต์</option>
          {models.map((model) => (
            <option key={model} value={model}>{model}</option>
          ))}
        </select>
        <input
          type="number"
          placeholder="จำนวนเงินเอาประกันภัย"
          className="input input-bordered w-full"
          onChange={(e) => setSumInsured(Number(e.target.value))}
        />
        <div className="flex items-center space-x-4">
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="yearRange"
              className="radio"
              checked={selectedYearRange === '6-10'}
              onChange={() => setSelectedYearRange('6-10')}
            />
            <span className="label-text ml-2">6 - 10 ปี</span>
          </label>
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="yearRange"
              className="radio"
              checked={selectedYearRange === '11-20'}
              onChange={() => setSelectedYearRange('11-20')}
            />
            <span className="label-text ml-2">11 - 20 ปี</span>
          </label>
        </div>
      </div>
      <button className="btn btn-primary mt-4" onClick={calculatePremium}>คำนวณเบี้ยประกันภัย</button>
      {premium !== null && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">เบี้ยประกันภัยสุทธิ: {premium.toLocaleString()} บาท</h2>
        </div>
      )}
    </div>
  );
};

export default InsuranceCalculator;
