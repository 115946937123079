import axios from "axios";
import { InsuranceDataType, VehicleBrandDataType, VehicleDataType } from "../interface/insuranceData";

const SHEET_ID = "1f-piB91nMhxZjcRmuW0mlgU97N7sI1wmporicgNiaYU";
const COMPANY_NAME = "company_name"; // ปรับตามชื่อชีทของคุณ
const VEHICLE_TYPE = "vehicle_type"; // ปรับตามชื่อชีทของคุณ
const VEHICLE_BRAND = "vehicle_brand"; // ปรับตามชื่อชีทของคุณ

export const fetchInsuranceTypeData = async (): Promise<InsuranceDataType[]> => {
  const url = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${COMPANY_NAME}`;

  try {
    const response = await axios.get(url);
    const rawData = JSON.parse(response.data.substr(47).slice(0, -2));

    // แปลงข้อมูลจาก Google Sheets เป็นรูปแบบที่ต้องการ
    const companyData: InsuranceDataType[] = rawData.table.rows.map(
      (row: any, index: number) => ({
        id: index + 1,
        company_name: row.c[0]?.v || 0,
        // แปลงข้อมูลอื่นๆ ตามโครงสร้างของคุณ
      })
    );

    return companyData;
  } catch (error) {
    console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
    throw error;
  }
};

export const fetchVehicleTypeData = async (): Promise<VehicleDataType[]> => {
  const url = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${VEHICLE_TYPE}`;

  try {
    const response = await axios.get(url);
    const rawData = JSON.parse(response.data.substr(47).slice(0, -2));

    // แปลงข้อมูลจาก Google Sheets เป็นรูปแบบที่ต้องการ
    const vehicleData: VehicleDataType[] = rawData.table.rows.map(
      (row: any, index: number) => ({
        id: index + 1,
        vehicle_type: row.c[0]?.v || 0,
        // แปลงข้อมูลอื่นๆ ตามโครงสร้างของคุณ
      })
    );

    return vehicleData;
  } catch (error) {
    console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
    throw error;
  }
};

export const fetchVehicleBrandData = async (): Promise<VehicleBrandDataType[]> => {
  const url = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${VEHICLE_BRAND}`;

  try {
    const response = await axios.get(url);
    const rawData = JSON.parse(response.data.substr(47).slice(0, -2));

    // แปลงข้อมูลจาก Google Sheets เป็นรูปแบบที่ต้องการ
    const vehicleBrandData: VehicleBrandDataType[] = rawData.table.rows.map(
      (row: any, index: number) => ({
        id: index + 1,
        vehicle_brand: row.c[0]?.v || 0,
        // แปลงข้อมูลอื่นๆ ตามโครงสร้างของคุณ
      })
    );

    return vehicleBrandData;
  } catch (error) {
    console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
    throw error;
  }
};
